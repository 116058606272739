import React from 'react'
import { Link } from 'gatsby'

import Layout from '../Components/Layout'
import SEO from '../Components/seo'

const SecondPage = () => (
    <Layout>
        <SEO title="Firm" />

        <span
            className="block mx-auto 
    text-gold font-bold text-lg
    max-w-lg text-center mt-4"
        >
            Firm
        </span>
        <h1
            className="text-5xl text-center font-serif
        mx-auto mb-12 max-w-lg
        border-b-2 border-gold"
        >
            PREBLE LAW FIRM
        </h1>
        <div
            className="container mx-auto mb-16 
        flex flex-col sm:flex-row 
        align-start justify-start"
        >
            <div className="w-full sm:w-2/3 sm:ml-8 pb-8 px-4">
                <p className="text-lg text-justify">
                    Text here talking about the firm and services they provide
                </p>
            </div>
        </div>
    </Layout>
)

export default SecondPage
